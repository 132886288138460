<mat-card
  fxLayout="column"
  class="full-height"
  gaCategoryGroup="ClaimsByPeriodChart"
>
  <mat-card-header fxLayout="row" fxLayoutAlign="start center">
    <mat-card-title class="no-margin">
      {{ 'claim-management.dashboard.claims_by_period' | translate }}
      <br />
      <small
        *ngIf="
          repairSentDateCurrentPercentage >= 0 &&
          modeSelector.value === periodMode.Create
        "
        [ngClass]="{
          'error-text':
            repairSentDateCurrentPercentage < repairSentDatePercentageGoal,
          'success-text':
            repairSentDateCurrentPercentage >= repairSentDatePercentageGoal
        }"
      >
        {{
          'claim-management.dashboard.claims_under_days'
            | translate
              : {
                  currentPercentage:
                    repairSentDateCurrentPercentage | number: '1.0-2',
                  days: repairSentDateDays,
                  goalPercentage: repairSentDatePercentageGoal | number: '1.0-2'
                }
        }}
      </small>
    </mat-card-title>
    <div fxFlex></div>
    <button
      mat-icon-button
      *ngIf="isDecisionGA$ | async"
      (click)="openSettings()"
      [disabled]="(claimsByPeriod$ | async) === null"
      gaclicklistener="DashboardCustomPeriods"
      gacategorygroup="DashboardCustomPeriods"
    >
      <mat-icon>settings</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content fxFlex fxLayout="column" fxLayoutAlign="top">
    <div fxLayout="row" [fxLayoutGap]="1 | dsSpacing" fxLayout.lt-md="column">
      <mat-form-field fxFlex="auto">
        <mat-select
          [formControl]="modeSelector"
          gaClickListener="SelectPeriodMode"
          (selectionChange)="getData()"
        >
          <mat-option [value]="periodMode.Create">{{
            ('claim-management.dashboard.created' | translate) || 'Created'
          }}</mat-option>
          <mat-option [value]="periodMode.Return">{{
            'claim-management.dashboard.Returned' | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [formGroup]="dateRange">
        <mat-label>{{
          'claim-management.dashboard.date_range' | translate
        }}</mat-label>
        <mat-date-range-input
          [rangePicker]="picker"
          [max]="today"
          [min]="'01 Jan 2020'"
          gaClickListener="SelectDateRange"
        >
          <input
            matStartDate
            formControlName="from"
            readonly
            (click)="picker.open()"
          />
          <input
            matEndDate
            formControlName="to"
            readonly
            (click)="picker.open()"
          />
        </mat-date-range-input>

        <mat-date-range-picker
          (closed)="getData()"
          [calendarHeaderComponent]="DsPresetCalenderHeaderComponent"
          #picker
        ></mat-date-range-picker>
        <div matIconSuffix class="nowrap-text">
          <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker-toggle (click)="dateRange.reset(); getData()">
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
        </div>
      </mat-form-field>
    </div>
    <ng-container
      *ngIf="claimsByPeriod$ | async as claimsByPeriod; else pieChartLoading"
    >
      <div
        *ngIf="
          claimsByPeriod.chartDataGa?.groups?.length ||
            claimsByPeriod.chartDataSp?.groups?.length;
          else noData
        "
      >
        <ngx-charts-bar-vertical-stacked
          fxFlex
          *ngIf="claimsByPeriod.chartDataGa?.groups?.length"
          [view]="view"
          [scheme]="$any(colorScheme)"
          [results]="claimsByPeriod.chartDataGa?.groups"
          [gradient]="false"
          [yAxis]="true"
          [legend]="true"
          [legendTitle]="
            claimsByPeriod.chartDataSp?.groups?.length
              ? ('claim-management.dashboard.claims_by_ga' | translate)
              : ''
          "
          [legendPosition]="legendPosition"
          (select)="goToClaimsByPeriod($event)"
          class="chart"
        >
          <ng-template #tooltipTemplate let-model="model">
            <h5>
              {{ model.name }}: <br />
              {{ model.value }}
            </h5>
            <hr />
            {{
              model.value / (claimsByPeriod.chartDataGa?.total_count || 0)
                | percent
            }}
            <br />
            <br />
          </ng-template>
        </ngx-charts-bar-vertical-stacked>
        <ngx-charts-bar-vertical-stacked
          fxFlex
          *ngIf="claimsByPeriod.chartDataSp?.groups?.length"
          [view]="view"
          [scheme]="$any(colorScheme)"
          [results]="claimsByPeriod.chartDataSp?.groups"
          [gradient]="false"
          [yAxis]="true"
          [legend]="true"
          [legendTitle]="
            claimsByPeriod.chartDataGa?.groups?.length
              ? ('claim-management.dashboard.claims_by_sp' | translate)
              : ''
          "
          [legendPosition]="legendPosition"
          (select)="goToClaimsByPeriod($event)"
          class="chart"
        >
          <ng-template #tooltipTemplate let-model="model">
            <h5>
              {{ model.name }}: <br />
              {{ model.value }}
            </h5>
            <hr />
            {{
              model.value / (claimsByPeriod.chartDataSp?.total_count || 0)
                | percent
            }}
            <br />
            <br />
          </ng-template>
        </ngx-charts-bar-vertical-stacked>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>

<ng-template #pieChartLoading>
  <div class="full-height" fxLayoutAlign="center center">
    <mat-progress-spinner
      [mode]="'indeterminate'"
      color="accent"
      [diameter]="100"
    >
    </mat-progress-spinner>
  </div>
</ng-template>

<ng-template #noData>
  <div class="full-height" fxLayoutAlign="center center">
    <ds-placeholder [type]="'no_data'"></ds-placeholder>
  </div>
</ng-template>
